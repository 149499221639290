import { Box, Divider, Stack, Typography } from "@mui/material";
import { format } from "date-fns";

import ReactionCardContextMenu from "components/feed/ReactionCardContextMenu";
import Markdown from "components/markdown/Markdown";
import Avatar from "components/shared/Avatar";
import Truncate from "components/shared/Truncate";
import useMe from "hooks/useMe";
import { AnswerReaction } from "lib/motorcade/types";

type Props = {
  answer: AnswerReaction;
};

export default function Answer({ answer }: Props) {
  const { id: meId } = useMe();
  const { user, data, created_at } = answer || {};
  const isMyOwnAnswer = meId === user?.id;

  return (
    <Box mt="16px">
      <Box
        sx={(theme) => ({
          position: "relative",

          "& .context-menu": {
            display: "none",
            position: "absolute",
            top: 0,
            right: 0,
          },

          "&:hover .context-menu": {
            display: "flex",
          },

          [theme.breakpoints.down("lg")]: {
            "& .context-menu": {
              display: "flex",
            },
          },
        })}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <Avatar alt={user?.data?.name} src={user?.data?.avatar} />
          <div>
            <Stack alignItems="center" direction="row">
              <Typography variant="body2">{user.data.name}</Typography>
              <Typography color="textSecondary" variant="caption">
                <Stack alignItems="center" direction="row">
                  <div className="dividerDot" />
                  {format(new Date(created_at), "MMMM d 'at' h:mm a")}
                </Stack>
              </Typography>
            </Stack>

            <Typography color="textSecondary" variant="caption">
              {user.data.company}
            </Typography>
          </div>
        </Stack>

        <Truncate maxLines={3} mt={1}>
          <Typography component="span" variant="body2">
            <Markdown markdown={data.body} />
          </Typography>
        </Truncate>

        {!isMyOwnAnswer && (
          <div className="context-menu">
            <ReactionCardContextMenu reaction={answer} />
          </div>
        )}
      </Box>

      <Divider sx={{ mt: 1 }} />
    </Box>
  );
}
